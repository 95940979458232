<template>
  <component
    :is="as"
    :class="cn(chipVariants({ variant, color }), props.class)"
  >
    <slot>{{ text }} </slot>
    <UiIcon
      v-if="closable"
      name="close"
      aria-label="Close"
      class="ml-1 size-4 cursor-pointer text-primary shrink-0"
      @click="handlerClickClose"
    />
  </component>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { type ChipVariants, chipVariants } from "./variant";

const props = withDefaults(
  defineProps<{
    variant?: ChipVariants["variant"];
    color?: ChipVariants["color"];
    class?: HTMLAttributes["class"];
    closable?: boolean;
    as?: string;
    text?: string;
  }>(),
  {
    as: "span",
  },
);

const emit = defineEmits(["click:close"]);

function handlerClickClose() {
  emit("click:close");
}
</script>
